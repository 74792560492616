var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{
      'notification-header':
        !_vm.displayInPage &&
        (_vm.visibleHeader ||
          (!_vm.isLoading && _vm.notifications && _vm.notifications.length) ||
          _vm.selectedNotificationTypes),
      'tw-flex tw-items-center tw-justify-between tw-pb-4 md:tw-pt-0 tw-pt-2 tw-min-w-0 max-md:tw-w-full tw-gap-4': _vm.displayInPage
    }},[(_vm.visibleHeader)?_c('div',{staticClass:"tw-pb-2 title"},[_c('fa',{attrs:{"icon":_vm.icons.bell}}),_vm._v("\n      "+_vm._s(_vm.$t('notifications'))+"\n    ")],1):_vm._e(),_vm._v(" "),(
        (!_vm.isLoading && _vm.notifications && _vm.notifications.length) ||
          _vm.selectedNotificationTypes
      )?_c('div',{staticClass:"max-md:tw-w-full",class:{ 'xl:tw-w-[20rem] lg:tw-w-[14rem]': _vm.displayInPage }},[_c('div',[_c('div',{staticClass:"tw-flex tw-justify-between tw-gap-4"},[_c('label',[_vm._v(_vm._s(_vm.$t('notification type')))]),_vm._v(" "),(_vm.canSeeSettings && !_vm.visibleHeader)?_c('CLink',{staticClass:"tw-text-grey-700 tw-px-2",attrs:{"to":_vm.notificationsSettingsUrl,"target":"_blank","title":_vm.$t('manage notifications')}},[_c('fa',{attrs:{"icon":_vm.icons.gear}})],1):_vm._e()],1)]),_vm._v(" "),_c('CCustomSelect',{ref:"customSelect",staticClass:"notification-dropdown tw-w-full",attrs:{"variant":"outline","no-unselect":"","size":"sm","no-portal":"","disabled":_vm.isLoading,"placeholder":_vm.$t('all::notifications'),"multi-select":"","options":_vm.notificationTypesOptions,"show-all-button":false},on:{"change":_vm.handleNotificationTypeChange},scopedSlots:_vm._u([{key:"checkboxLabel",fn:function(ref){
      var option = ref.option;
return [_c('COptionWithCount',{attrs:{"count":option.count,"text":option.text}})]}}],null,false,2835580527),model:{value:(_vm.selectedNotificationTypes),callback:function ($$v) {_vm.selectedNotificationTypes=$$v},expression:"selectedNotificationTypes"}})],1):_vm._e(),_vm._v(" "),(_vm.visibleHeader)?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center tw-pt-2"},[(_vm.canSeeSettings)?_c('CLink',{staticClass:"tw-text-grey-700 tw-px-2",attrs:{"to":_vm.notificationsSettingsUrl,"target":"_blank","title":_vm.$t('manage notifications')}},[_c('fa',{attrs:{"icon":_vm.icons.gear}})],1):_vm._e()],1):_vm._e(),_vm._v(" "),(
        !_vm.infiniteScrollPagination &&
          _vm.notifications &&
          _vm.notifications.length &&
          _vm.pagination.total > _vm.pagination.perPage
      )?_c('CPagination',{staticClass:"tw-hidden md:tw-flex tw-self-end",attrs:{"pages-count":_vm.paginationPages,"page":_vm.pageNum,"disabled":_vm.isLoading},on:{"change":_vm.handlePageChange}}):_vm._e()],1),_vm._v(" "),_c('COverlay',{ref:"overlay",staticClass:"tw-border-0 tw-rounded-none",class:{
      overlay: _vm.displayInPage,
      'notification-overlay popover ': !_vm.displayInPage,
      'tw-w-full tw-h-full':
        !_vm.isLoading && _vm.notifications && !_vm.notifications.length,
      loading: _vm.isResultsLoading
    },attrs:{"show":_vm.isLoading,"spinner-variant":"primary"}},[(_vm.notifications && _vm.notifications.length)?_c('NotificationCenterList',{attrs:{"notifications":_vm.notifications,"display-in-page":_vm.displayInPage}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"no-results"},[(!_vm.isLoading && _vm.canSeeSettings && !_vm.notifications.length)?_c('CLink',{staticClass:"tw-text-grey-700 tw-px-2 tw-absolute tw-z-10 tw-right-2 tw-top-3",attrs:{"to":_vm.notificationsSettingsUrl,"target":"_blank","title":_vm.$t('manage notifications')}},[_c('fa',{attrs:{"icon":_vm.icons.gear}})],1):_vm._e(),_vm._v(" "),(!_vm.isLoading && _vm.notifications && !_vm.notifications.length)?_c('NoResultsFound',{staticClass:"tw-flex-col tw-items-center",class:{
          'tw-flex tw-flex-1 tw-rounded-none tw-border-none tw-justify-center tw-mb-0': !_vm.displayInPage
        },attrs:{"subtitle":' ',"header":_vm.$t('there are no notifications'),"icon":['fa', 'bell'],"variant":"secondary"}}):_vm._e()],1),_vm._v(" "),(
        _vm.infiniteScrollPagination &&
          _vm.notifications &&
          _vm.notifications.length &&
          _vm.pagination.total > _vm.pagination.perPage * _vm.pagination.page
      )?_c('CInfiniteScroll',{on:{"scroll":function($event){return _vm.handlePageChange(_vm.pageNum + 1)}}}):_vm._e()],1),_vm._v(" "),(!_vm.infiniteScrollPagination && _vm.notifications && _vm.notifications.length)?_c('div',{staticClass:"tw-flex tw-justify-between tw-items-start tw-gap-4 tw-py-5 tw-flex-wrap max-md:tw-flex-col max-md:tw-items-center"},[_c('CButton',{attrs:{"size":"sm","variant":"secondary","outline":"","icon":_vm.icons.check,"type":"button","disabled":_vm.unreadNotifications === 0 || _vm.isLoading},on:{"click":_vm.onReadAll}},[_vm._v(_vm._s(_vm.$t('mark_all_read')))]),_vm._v(" "),(_vm.pagination.total > _vm.pagination.perPage)?_c('CPagination',{staticClass:"md:tw-ml-auto",attrs:{"page":_vm.pageNum,"results-text":_vm.$t('notifications').toString(),"total-results":_vm.pagination.total,"show-counts":"","pages-count":_vm.paginationPages,"disabled":_vm.isLoading},on:{"change":_vm.handlePageChange}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }